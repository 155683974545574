import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content, Map } from "page_components/contact"

const Contact = ({ location }) => {
  const title = "Kontakt"

  return (
    <Layout
      location={location}
      seo={{
        title: title,
        description:
          "Kupno mieszkania? Sprzedaż gruntu? Współpraca? W Trust Investment wierzymy, że niezależnie od zagadnienia warto rozmawiać. Porozmawiajmy o dobrej współpracy!",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content />
      <Map />
    </Layout>
  )
}

export default Contact
